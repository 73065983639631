<template>
  <BaseModal
    name="identity-validation"
    :size="size"
    title="Verificação de Identidade"
    @hidden="closeModal"
    @shown="openModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div class="container" v-if="!loading">
      <div v-if="step == 1 && !no_cam.negado" data-anima="right">
        <!-- <h2>Vamos iniciar a verificação</h2> -->
        <h4 class="mb-4">Requisitos para realizar a verificação</h4>

        <ul class="requisitos">
          <li class="ml-1">
            <img src="@/assets/img/icons/validation-doc.svg" />
            <p>Documento de identidade com foto e CPF</p>
          </li>
          <li>
            <img src="@/assets/img/icons/validation-cam.svg" />
            <p>Dispositivo com acesso a câmera</p>
          </li>
          <li>
            <img src="@/assets/img/icons/validation-light.svg" />
            <p>Ambiente com boa iluminação</p>
          </li>
        </ul>

        <div>
          <BaseButton
            variant="secondary_outline"
            class="btn-modal init"
            @click="nextStep"
            >Iniciar a verificação</BaseButton
          >
        </div>
      </div>

      <div v-if="step == 2 && !no_cam.negado" data-anima="left">
        <div v-if="!tiraFoto">
          <h2>Documento de Identidade</h2>
          <h4 class="mb-4">Use um documento válido emitido pelo Governo</h4>

          <ul class="requisitos dicas">
            <li>
              <img src="@/assets/img/icons/mini-check.svg" />
              <p>Tamanho original sem edições</p>
            </li>
            <li>
              <img src="@/assets/img/icons/mini-check.svg" />
              <p>Imagens coloridas, claras, iluminadas e legíveis</p>
            </li>
            <li>
              <img src="@/assets/img/icons/mini-x.svg" />
              <p>Não envie imagens em preto e branco</p>
            </li>
            <li>
              <img src="@/assets/img/icons/mini-x.svg" />
              <p>Não envie documentos vencidos ou editados</p>
            </li>
          </ul>
        </div>

        <div v-if="tiraFoto">
          <h2>
            {{
              frente_verso == 1 ? "Frente do documento" : "Verso do documento"
            }}
          </h2>
          <h4 class="mb-4">
            Adicione uma foto
            {{
              frente_verso == 1
                ? "da Frente do documento"
                : "do Verso do documento"
            }}
          </h4>
        </div>

        <ImageRecord v-if="tiraFoto" @close="getFoto" :isMac="isMac" />
        <div class="docs" v-if="!tiraFoto">
          <div class="doc-item" @click="tirarFoto(1)" v-if="!foto.frente">
            <img src="@/assets/img/icons/validation-doc.svg" />
            <span>Frente do documento</span>
          </div>
          <img
            :src="foto.frente"
            v-if="foto.frente"
            @click="tirarFoto(1)"
            class="foto-item"
            id="foto-item-1"
          />
          <b-tooltip target="foto-item-1" title="Clique para alterar" />
          <div class="doc-item" @click="tirarFoto(2)" v-if="!foto.verso">
            <img src="@/assets/img/icons/validation-doc.svg" />
            <span>Verso do documento</span>
          </div>
          <img
            :src="foto.verso"
            v-if="foto.verso"
            @click="tirarFoto(2)"
            class="foto-item"
            id="foto-item-2"
          />
          <b-tooltip target="foto-item-2" title="Clique para alterar" />
        </div>
      </div>

      <div v-if="step == 3 && !no_cam.negado" data-anima="left">
        <h2>{{ isMac ? "Vídeo de Apresentação" : "Reconhecimento Facial" }}</h2>
        <h4>
          {{
            isMac
              ? "Grave seu vídeo de apresentação"
              : "Tenha um dispositivo com acesso a câmera"
          }}
        </h4>
        <p class="mb-4 mt-3" v-if="!isMac">
          Agora, confirmaremos a sua identidade. Para começar, olhe para a
          câmera e siga as instruções que aparecem na tela.
        </p>
        <p class="mb-4 mt-3" v-if="isMac">
          Para sua validação de identidade, é preciso que você esteja em um
          local bem iluminado, sem o uso de acessórios como boné e óculos,
          apresente juntamente com sua face todos os dados da documentação.
        </p>

        <div class="dicas-container">
          <div class="dica-item">
            <img src="@/assets/img/icons/bone.svg" />
            <span>Evite o uso de chapéus</span>
          </div>
          <div class="dica-item">
            <img src="@/assets/img/icons/oculos.svg" />
            <span>Evite o uso de óculos</span>
          </div>
          <div class="dica-item">
            <img src="@/assets/img/icons/filtros-foto.svg" />
            <span>Evite o uso de filtros</span>
          </div>
          <div class="dica-item">
            <img src="@/assets/img/icons/luz.svg" />
            <span>Iluminação apropriada</span>
          </div>
        </div>
      </div>

      <div v-if="no_cam.negado" data-anima="top" class="no-cam">
        <img src="@/assets/img/icons/no_cam.svg" />

        <div v-if="no_cam.type == 'em uso'">
          <h2>Câmera sendo utilizada</h2>

          <p>
            Atualmente a câmera está sendo utilizada em outro aplicativo de
            forma simultânea. Desconecte do outro app e tente novamente
          </p>
        </div>

        <div v-else>
          <h2>Acesso à câmera negado</h2>

          <p>
            Precisamos do acesso à sua câmera para verificação de imagem. Acesse
            as configurações do seu navegador e permita o acesso à câmera.
          </p>
        </div>

        <BaseButton
          variant="accent_outline"
          class="btn-no_cam"
          @click="resetCam"
          >Entendido</BaseButton
        >
      </div>
      <div
        v-if="step == 4 && !no_cam"
        data-anima="left"
        class="video-container"
      >
        <VideoRecord
          v-if="!isMac"
          :poses="poses"
          :closeAll="closeAll"
          @confirm="confirmSubmitVideo"
          @update="fetchPoses"
        />
        <UploadVideo v-if="isMac" @confirm="confirmSubmitVideo" />
      </div>

      <div v-if="step == 5 && !no_cam" data-anima="top" class="no-cam">
        <img src="@/assets/img/icons/confirm-validation.svg" />
        <h2>Tudo certo</h2>

        <p>
          Seus dados foram enviados para a equipe de análise. Você receberá o
          retorno em breve
        </p>

        <BaseButton
          variant="secondary_outline"
          class="btn-no_cam"
          @click="closeModal"
          >Entendido</BaseButton
        >
      </div>

      <!-- <div class="progress"></div> -->
      <div
        class="container-progress"
        v-show="step > 1 && !no_cam && step != 4 && step != 5 && !tiraFoto"
      >
        <b-progress
          :value="progress_value"
          max="100"
          class="is-blue"
        ></b-progress>
      </div>
    </div>
    <template v-slot:footer="{}">
      <div>
        <BaseButton
          variant="link-info"
          v-show="step > 1 && !no_cam && step != 4 && step != 5 && !tiraFoto"
          class="mr-3"
          :disabled="loading"
          @click="previousStep"
          >Voltar</BaseButton
        >

        <BaseButton
          variant="secondary_outline"
          v-show="step > 1 && !no_cam && step != 4 && step != 5 && !tiraFoto"
          :disabled="(step == 2 && (!foto.frente || !foto.verso)) || loading"
          @click="nextStep"
          >{{
            step != 3
              ? "Continuar"
              : isMac
              ? "Adicionar meu vídeo"
              : "Iniciar Reconhecimento"
          }}</BaseButton
        >
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { EventBus } from "@/main.js";
import UserService from "@/services/resources/UserService";
const serviceUser = UserService.build();

import ValidationService from "@/services/resources/ValidationService";
const serviceValidation = ValidationService.build();

import VideoRecord from "./VideoRecord.vue";
import UploadVideo from "./UploadVideo.vue";
import ImageRecord from "./ImageRecord.vue";

import { datadogLogs } from "@datadog/browser-logs";

datadogLogs.init({
  applicationId: "d7aa09b9-670c-4509-9c91-bfda49d83380",
  clientToken: "pub372ec661a0dd1ccc5d00061f24210946",
  site: "datadoghq.com",
  service: "adm",
  env: "production",
  version: "1.0.0",
  sampleRate: 100,
  replaySampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

export default {
  name: "IdentifyValidation",
  data() {
    return {
      size: "md",
      loading: false,
      closeAll: false,
      progress_value: 15,
      poses: [],
      max: 100,
      no_cam: false,
      step: 1,
      preview: null,
      tiraFoto: false,
      frente_verso: 0,
      foto: {
        frente: null,
        verso: null,
      },
    };
  },
  components: {
    VideoRecord,
    UploadVideo,
    ImageRecord,
  },
  computed: {
    userId() {
      return this.$store.getters.getProfile.id;
    },
    isMac() {
      return window.localStorage.isMac == "true" ? 1 : 0;
    },
  },

  methods: {
    openModal() {
      this.loading = true;

      const user = this.$store.state.user.user;

      if (user.cpf_cnpj && user.email && user.rg) {
        this.fetchPoses().then((resp) => {
          this.poses = resp;
        });
      } else {
        this.$bvModal.hide("identity-validation");

        EventBus.$emit("incomplete");

        if (this.$route.path != "/minha-conta") {
          this.$router.push("/minha-conta");
        }
      }
    },
    fetchPoses() {
      return new Promise((resolve) => {
        this.loading = true;
        serviceValidation
          .create()
          .then((response) => {
            this.loading = false;
            resolve(response);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            resolve(false);
          });
      });
    },
    closeModal() {
      this.$emit("close");
      this.$bvModal.hide("identity-validation");
      this.reset();
    },

    jsonToFormData(data) {
      const formData = new FormData();

      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    },

    confirmSubmitVideo(data) {
      this.loading = true;

      let requestData = {
        doc_front: this.foto.frente,
        doc_back: this.foto.verso,
        video: new File([data], "video", { type: data.type }),
        is_mac: this.isMac,
      };

      datadogLogs.logger.warn("🟡 Dados do Vídeo - 01", {
        name: "Enviado os dados do vídeo SEM base64",
        JSON: JSON.stringify(requestData),
        requestData: requestData,
      });

      this.toBase64(requestData.video).then((video64) => {
        requestData.video = video64;

        requestData = this.jsonToFormData(requestData);

        datadogLogs.logger.warn("🟡 Dados do Vídeo - 02", {
          name: "Enviado os dados do vídeo COM base64",
          JSON: JSON.stringify(requestData),
          requestData: requestData,
        });

        serviceValidation
          .create(requestData, "/upload")
          .then((r) => {
            this.nextStep();
            datadogLogs.logger.warn("✅ Dados do Vídeo - SUCESSO", {
              name: "Vídeo enviado com sucesso",
              dados: r,
            });
          })
          .catch((err) => {
            console.log(err);
            datadogLogs.logger.warn("❌ Dados do Vídeo - ERRO", {
              name: "Não foi possível enviar os dados",
              dados: err,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    saveImagesUser() {
      this.loading = true;
      let data = {
        images: [
          {
            name: "frente_doc",
            path: this.foto.frente,
          },
          {
            name: "verso_doc",
            path: this.foto.verso,
          },
        ],
        _method: "PUT",
      };

      serviceUser
        .create(data, this.userId)
        .catch((err) => {
          console.log(err);
          this.step == 2;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFoto(data) {
      this.tiraFoto = false;
      if (this.frente_verso == 1) {
        this.foto.frente = data;
      } else if (this.frente_verso == 2) {
        this.foto.verso = data;
      }
    },
    tirarFoto(index) {
      this.frente_verso = index;
      this.tiraFoto = true;
      if (this.frente_verso == 1) {
        this.foto.frente = null;
      } else if (this.frente_verso == 2) {
        this.foto.verso = null;
      }
    },
    reset() {
      this.closeAll = true;
      this.step = 1;
      this.frente_verso = 0;
      this.foto = {
        frente: null,
        verso: null,
      };
      this.tiraFoto = false;
    },
    resetCam() {
      this.previousStep();
      this.no_cam = false;
    },
    nextStep() {
      this.step++;
      this.progress_value += 15;
      if (this.step == 4) {
        this.size = "lg";
      } else {
        this.size = "md";
      }
    },
    previousStep() {
      this.step--;
      this.progress_value -= 15;
    },
  },
  mounted() {
    if (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
      window.localStorage.isMac = true;
    } else {
      window.localStorage.isMac = false;
    }
  },
  created() {
    EventBus.$on("videoPermission", (data) => {
      this.no_cam = data;
    });
  },
};
</script>

<style scoped>
.container {
  padding: 0px 20px !important;
}
/* .btn-modal {
  padding: 0 25px !important;
  font-size: 13px;
  margin-top: 30px;
} */
.btn-modal.init {
  margin-top: 30px;
  width: 100%;
}

p {
  color: #81858e;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}
h4 {
  color: #2a63ab;
  font-size: 14px;
  font-weight: 600;
}
.requisitos {
  margin-top: 40px;
  display: block;
}
.requisitos li {
  display: flex;
  align-items: center;
  gap: 20px;
}
.requisitos li + li {
  margin-top: 30px;
}
.dicas {
  margin-top: 0;
}
.dicas li + li {
  margin-top: 12px;
}
.docs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 25px;
}
.doc-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  background: #f7f7f7;
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
}
.foto-item {
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
  height: 150px;
  object-fit: cover;
  background: #f7f7f7;
}
.doc-item img {
  filter: invert(50%) brightness(0);
}
.doc-item span {
  font-size: 13px;
}
.container-progress {
  max-width: 150px;
  margin: 40px 0 10px 0;
}
.progress {
  background: #f7f7f7;
  height: 7px;
  border-radius: 5px;
}
.dicas-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.dica-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  text-align: center;
}
.dica-item span {
  font-weight: 600;
  font-size: 14px;
}
.no-cam *,
.no-cam {
  margin: 0 auto;
  text-align: center;
}
.no-cam h2 {
  margin: 30px 0;
}
.no-cam .btn-no_cam {
  width: 60%;
  margin-top: 40px;
}
</style>
