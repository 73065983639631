<template>
  <div class="container-menu">
    <item v-if="!$store.getters.setPanel || ($store.getters.setPanel && dashboard)" route="Dashboard" name="Dashboard" icon="dashboard">Dashboard</item>
    <item v-if="!$store.getters.setPanel || ($store.getters.setPanel && vendas)" route="Sales" name="Vendas" icon="my-sales" />
    <item v-if="!$store.getters.setPanel" route="Contracts" name="Contratos" icon="my-contracts" />
    <item v-if="!$store.getters.setPanel" route="Products" name="Produtos" icon="products" />
    <!-- <item route="Vitrine" name="Vitrine" icon="checkout-link" /> -->
    <item v-if="!$store.getters.setPanel" route="Links" name="Links" icon="links" />
    <item v-if="!$store.getters.setPanel" route="Statements" name="Extrato" icon="extrato" />
    <item v-if="!$store.getters.setPanel || ($store.getters.setPanel && cupom)" route="Coupon" name="Cupom" icon="cupom" />
    <item v-if="!$store.getters.setPanel" route="NoteCenter" name="Central de Notas" icon="notas" />
    <item v-if="!$store.getters.setPanel" route="Checkouts" name="Checkouts" icon="grid" />
    <item v-if="!$store.getters.setPanel" route="Affiliation" name="Afiliados" icon="affiliate" />
    <item v-if="!$store.getters.setPanel" route="Leads" name="Leads" icon="award" />
    <item v-if="!$store.getters.setPanel" route="Reclamações" name="Reclamações" icon="triangle" />
    <!-- <item route="MyAccount" name="Conta" icon="user-config" /> -->
    <item v-if="!$store.getters.setPanel" route="Settings" name="Sistema" icon="company-config" />
    <div class="Menu-item-logout" @click.prevent="logout">
      <a href="#">
        <img src="@/assets/img/icons/sign-out.svg" />
        Deslogar
      </a>
    </div>
  </div>
</template>
<script>
import Item from "./Item";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      dashboard: false,
      vendas: false,
      cupom: false,
    };
  },
  components: {
    Item,
  },
  computed: {
    isSeller() {
      return this.$store.getters.isSeller;
    },
  },
  mounted(){
    this.getPermissions();
    this.$root.$on("reset-all", (data) => {
      this.getPermissions();
    });
    this.$root.$on("reset-permissions", (data) => {
      this.getPermissions();
    });
  },
  methods: {
    getPermissions(){
      if(this.$store.getters.setPanel){
        var dados = Cookies.get("setPanelPermission");
        // console.log(dados);
        if (dados) {
          dados = JSON.parse(dados);
          if (dados.find((x) => x.name === 'DashboardRead')) {
            this.dashboard = true;
          }
          if (dados.find((x) => x.name === 'SaleRead')) {
            this.vendas = true;
          }
          if (dados.find((x) => x.name === 'CouponRead')) {
            this.cupom = true;
          }
        }
      }
    },
    logout() {
      this.$store.dispatch("logoutRequest").catch(function () {});
    },
  },
  data() {
    return {
      dashboard: false,
      vendas:false,
      itemsSeller: [
        { route: "Sales", name: "Minhas vendas" },
        { route: "Contracts", name: "Meus contratos" },
      ],
      itemsClient: [
        { route: "Client-Purchases", name: "Minhas compras" },
        { route: "Client-Subscriptions", name: "Minhas assinaturas" },
      ],
    };
  },
};
</script>

<style scoped>
.container-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 10px;
  width: 100%;
  /* height: 80vh; */
  overflow: auto;
}
.Menu-item,
.Menu-item-logout {
  height: 70px;
}

.Menu-item a {
  color: #333 !important;
}
.Menu-item-logout {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.Menu-item-logout a {
  width: 100%;
  padding: 20px;
  display: flex;
  text-decoration: none;
  color: #333;
  font-size: 13px;
  font-weight: 600;
}
.Menu-item-logout img {
  margin-right: 20px;
  width: 17px;
}
.Menu-item-logout:hover {
  background-color: #ff0c37;
  cursor: pointer;
}
.Menu-item-logout:hover a {
  color: #fff;
}
.Menu-item-logout:hover img {
  filter: brightness(50) invert(0);
}
</style>