import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2'
import './assets/scss/multiple-select.scss'
import {
  library
} from '@fortawesome/fontawesome-svg-core';
import {
  fas
} from '@fortawesome/free-solid-svg-icons';
import {
  fab
} from '@fortawesome/free-brands-svg-icons';
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome';
import VeeValidate from 'vee-validate';
import {
  Validator
} from 'vee-validate';
import pt_BR from 'vee-validate/dist/locale/pt_BR';
import router from './router';
import store from './store';
import {
  date,
  datetime
} from './filters/date';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import VueTheMask from 'vue-the-mask';
import validate_cpf_cnpj from './validators/cpf_cnpj';
import components from './components/index'
import ModalValidation from '@/components/Client/Identity/ModalValidation'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

// editor MarkDown
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import hljs_editor from 'highlight.js/lib/core';

// Preview MarkDown
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import hljs_preview from 'highlight.js';

// Estilo MarkDown
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
import ptBR from '@/assets/lang/pt-BR.js';
import json from 'highlight.js/lib/languages/json'

import {
  datadogRum
} from '@datadog/browser-rum';

import CallEvaluation from './views/Seller/CallEvaluation'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)
Vue.component('CallEvaluation', CallEvaluation)


export const EventBus = new Vue();

for (let componentKey in components) {
  Vue.component(componentKey, components[componentKey])
}

import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha/src/index.js'
Vue.component('vue-programmatic-invisible-google-recaptcha', VueProgrammaticInvisibleGoogleRecaptcha)

import ModalGroupAccept from '@/components/Seller/Settings/ModalGroupAccept.vue'
Vue.component('modal-group-accept', ModalGroupAccept)

if (process.env.VUE_APP_API_HOST == 'https://apiadm.voompcreators.com.br') {
  datadogRum.init({
    applicationId: 'd7aa09b9-670c-4509-9c91-bfda49d83380',
    clientToken: 'pub372ec661a0dd1ccc5d00061f24210946',
    site: 'datadoghq.com',
    service: 'adm',
    env: 'production',
    version: '1.0.0',
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording();
}



Vue.use(VueTheMask)
library.add(fas);
library.add(fab);
Vue.use(VeeValidate)
Validator.localize('pt_BR', pt_BR);
Validator.extend('cpf_cnpj', validate_cpf_cnpj);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('ModalValidation', ModalValidation);

Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);

Vue.filter('date', date);
Vue.filter('datetime', datetime);


// Config MarkDown
VueMarkdownEditor.lang.use('en-US', enUS);
VueMarkdownEditor.lang.add({
  'en-US': ptBR
})
hljs_editor.registerLanguage('json', json);
VueMarkdownEditor.use(githubTheme, {
  Hljs: hljs_editor,
});

VMdPreview.use(githubTheme, {
  Hljs: hljs_preview,
});

Vue.use(VueMarkdownEditor);
Vue.use(VMdPreview);
// Config MarkDown




Vue.config.productionTip = false;


router.beforeEach((to, from, next) => {
  const menu = document.querySelector(".Menu");
  if (menu) menu.classList.add("logo-close");
  next();
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')