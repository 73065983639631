<template>
  <keep-alive>
    <div>
      <div>
        <h2>Vídeo de Apresentação</h2>
        <p>
          Grave um vídeo confirmando que você realmente é o dono da conta e
          apresente seus documentos no mesmo, utilizando como suporte
          <a
            href="https://youtube.com/shorts/pqwzVCuGOpU?feature=share"
            target="_blank"
            >este vídeo</a
          >, após isso adicione o vídeo no campo abaixo
        </p>
        <b-form-file
          class="mt-3"
          id="video_upload"
          name="video_upload"
          plain
          v-model="video_upload"
          accept="video/*"
          @change="changeFile"
        ></b-form-file>
        <p class="text-center">Adicione o seu vídeo gravado aqui</p>
      </div>
      <div class="actions mt-3">
        <BaseButton
          variant="secondary_outline"
          @click="onSubmit"
          :disabled="!video_upload"
          >Subir vídeo de apresentação</BaseButton
        >
      </div>
    </div>
  </keep-alive>
</template>

<script>
import { datadogLogs } from "@datadog/browser-logs";

datadogLogs.init({
  applicationId: "d7aa09b9-670c-4509-9c91-bfda49d83380",
  clientToken: "pub372ec661a0dd1ccc5d00061f24210946",
  site: "datadoghq.com",
  service: "adm",
  env: "production",
  version: "1.0.0",
  sampleRate: 100,
  replaySampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

export default {
  data() {
    return {
      video_upload: null,
    };
  },

  methods: {
    changeFile(e) {
      this.video_upload = e.target.files[0];
    },
    onSubmit() {
      //  log video gravado
      datadogLogs.logger.warn("🟡 Dados do Vídeo - VÍDEO GRAVADO", {
        name: "Dados na etapa do video gravado",
        dados: this.video_upload,
      });
      this.$emit("confirm", this.video_upload);
    },
  },
};
</script>

<style scoped>
p {
  color: #81858e;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}

.actions {
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  gap: 20px;
}
.actions button {
  flex: 1;
}
</style>
