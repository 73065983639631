import middleware from '@/router/middleware'
import authentication from '@/router/middleware/authentication'
import unauthentication from '@/router/middleware/unauthentication'


export default [{
    path: '/',
    name: 'Login',
    component: () => import('../../views/Seller/Login.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/verificar-email',
    name: 'VerificaEmail',
    component: () => import('../../views/Seller/Verification.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/verificar-email/:hash',
    name: 'VerificaEmailHash',
    component: () => import('../../views/Seller/Verification.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/login-greenn',
    name: 'LoginGreenn',
    component: () => import('../../views/Seller/Logingreen.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/login-greenn-afiliado',
    name: 'afiliado',
    component: () => import('../../views/Seller/AffiliateInvitation.vue'),
    meta: {
      sidebar: false
    }
  },
  {
    path: '/registro',
    name: 'Seller-Register',
    component: () => import('../../views/Seller/Register.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/recrutamento/:hash',
    name: 'Recruitment',
    component: () => import('../../views/Seller/Recruitment.vue'),
  },
  {
    path: '/download/:product_id/:sale_id/:hash',
    name: 'DownloadFiles',
    component: () => import('../../views/Client/DownloadFiles.vue'),
  },
  {
    path: '/avaliacao/',
    name: 'Avaliacao',
    component: () => import('../../views/Client/Avaliacao.vue'),
  },
  {
    path: '/avaliacao/atendente',
    name: 'AttendantEvaluation',
    component: () => import('../../views/Client/AttendantEvaluation.vue'),
  },
  {
    path: '/recuperar-senha/',
    name: 'Seller-Password-Recovery',
    component: () => import('../../views/Seller/PasswordRecovery.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/recuperar-senha/:token',
    name: 'Seller-Password-Recovery',
    component: () => import('../../views/Seller/PasswordRecovery.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../../views/Seller/Dashboard.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/vendas/',
    name: 'Sales',
    component: () => import('../../views/Seller/Sales.vue'),
    beforeEnter: middleware([authentication]),
  },

  {
    path: '/contratos',
    name: 'Contracts',
    component: () => import('../../views/Seller/Contracts.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/produtos',
    name: 'Products',
    component: () => import('../../views/Seller/Products.vue'),
    beforeEnter: middleware([authentication]),
  },

  {
    path: '/produtos/edicao-produto/:id_product/',
    name: 'EditProduct',
    component: () => import('../../views/Seller/EditProduct.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/produtos/edicao-produto/:id_product/:code',
    name: 'EditProduct',
    component: () => import('../../views/Seller/EditProduct.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/extrato',
    name: 'Statements',
    component: () => import('../../views/Seller/Statements.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/afiliados',
    name: 'Affiliation',
    component: () => import('../../views/Seller/Affiliation.vue'),
    beforeEnter: middleware([authentication]),
  },

  {
    path: '/links',
    name: 'Links',
    component: () => import('../../views/Seller/Links.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/checkouts',
    name: 'Checkouts',
    component: () => import('../../views/Seller/Checkouts.vue'),
    beforeEnter: middleware([authentication]),
  },
   {
     path: '/cupom',
     name: 'Coupon',
     component: () => import('../../views/Seller/Cupom.vue'),
     beforeEnter: middleware([authentication]),
   },
  {
    path: '/configuracoes',
    name: 'Settings',
    component: () => import('../../views/Seller/Settings.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/minha-conta',
    name: 'MyAccount',
    component: () => import('../../views/Seller/MyAccount.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/definir-senha/:token',
    name: 'SetupPassword',
    component: () => import('../../views/Seller/SetupPassword.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  // Client
  {
    path: '/cliente/registro',
    name: 'Client-Register',
    component: () => import('../../views/Client/Register.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/compras',
    name: 'Client-Purchases',
    component: () => import('../../views/Client/Purchases.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/assinaturas',
    name: 'Client-Subscriptions',
    component: () => import('../../views/Client/Subscriptions.vue'),
    beforeEnter: middleware([authentication]),
  },
  // Common
  {
    name: 'AdminLogin',
    path: '/admin-login/:userid',
    component: () => import('../../views/AdminLogin.vue'),
  },
  {
    name: 'Redirect',
    path: '/redirect/:token',
    component: () => import('../../views/Redirect.vue'),
  },
  {
    name: 'Redirect',
    path: '/g/:token',
    component: () => import('../../views/Redirect.vue'),
  },
  {
    name: 'AffiliationInvite',
    path: '/afiliacao-solicitacao/:token',
    component: () => import('../../views/AffiliationInvite.vue'),
    beforeEnter: middleware([unauthentication])
  },
  {
    path: '/vitrine',
    name: 'Vitrine',
    component: () => import('../../views/Seller/Store.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/central-de-notas',
    name: 'NoteCenter',
    component: () => import('../../views/Seller/NoteCenter.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/login-to-google/:auth/:title',
    name: 'Login Google',
    component: () =>
        import ('../../views/Seller/LoginGoogleOff.vue'),
  },
  {
    path: '/relatorios',
    name: 'reports',
    component: () => import('../../views/Seller/Reports.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/gestao-leads',
    name: 'Leads',
    component: () => import('../../views/Seller/Leads.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/reclamacoes',
    name: 'Reclamações',
    component: () => import('../../views/Seller/Claim.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    name: 'pageNotFound',
    path: '*',
    component: () => import('../../views/PageNotFound.vue'),
  }
]