<template>
  <header>
    <h3>{{ title }}</h3>
    <nav>
      <slot></slot>
    </nav>
    <BaseView :header="true" v-if="$store.getters.setPanel && !isMobile" />
    <BaseView :header="true" v-if="isMobile" />
  </header>
</template>

<script>
import BaseView from "./BaseView.vue";

export default {
  components: {
    BaseView,
  },
  data() {
    return {
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
};
</script>

<style scoped lang="scss">
a {
  cursor: pointer;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  padding-bottom: 0;
  margin: 15px 0;
  position: relative;
  z-index: 999;
}

header h3 {
  font-size: 32px;
  font-weight: 600;
  color: #333;
  font-family: Montserrat;
  position: relative;
  z-index: 1;
}

.btn-header {
  border: none;
  background: #002363;
  color: #fff;
  outline: none;
  font-weight: 600;
  height: 50px;
  padding: 0 42px;
  border-radius: 5px;
  font-size: 14px;

  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: none !important;
}
.btn-header:hover {
  transform: scale(1.07);
  box-shadow: none !important;
}
.btn-header:focus {
  box-shadow: none !important;
}
.btn-header img {
  width: 16px;
  margin-right: 12px;
}

@media screen and (max-width: 768px) {
  header {
    display: block;
  }
  header h3 {
    font-size: 24px;
  }
  header h3::after {
    width: 1rem;
    height: 1rem;
  }
  nav {
    max-width: 100%;
  }
}
</style>
