<template>
  <div class="menu-links" :class="{ 'ajust-itens': $store.getters.setPanel }">
    <router-link
      to="/dashboard"
      v-if="!$store.getters.setPanel || ($store.getters.setPanel && dashboard)"
      ><img src="@/assets/img/icons/dashboard.svg" />Dashboard</router-link
    >

    <a
      @click="subMenu01"
      v-if="!$store.getters.setPanel || ($store.getters.setPanel && vendas)"
    >
      <img src="@/assets/img/icons/sales.svg" />
      Vendas
      <img
        src="@/assets/img/icons/arrow-fill.svg"
        class="arrow"
        :class="{ rotateArrow: sub_menu }"
      />
    </a>

    <router-link v-if="!$store.getters.setPanel" to="/relatorios"
      ><img src="@/assets/img/icons/relatorios.svg" />Relatórios</router-link
    >

    <router-link v-if="!$store.getters.setPanel" to="/produtos"
      ><img src="@/assets/img/icons/products.svg" />Produtos</router-link
    >

    <!-- <router-link v-if="!$store.getters.setPanel" to="/vitrine"
      ><img src="@/assets/img/icons/vitrine.svg" />Vitrine</router-link
    > -->

    <router-link v-if="!$store.getters.setPanel" to="/links"
      ><img src="@/assets/img/icons/links.svg" />Links</router-link
    >

    <a
      @click="subMenu02"
      v-if="!$store.getters.setPanel || ($store.getters.setPanel && cupom)"
    >
      <img src="@/assets/img/icons/more.svg" />Ver mais
      <!-- style="transform: rotate(90deg)" -->
      <img
        src="@/assets/img/icons/arrow-fill.svg"
        class="arrow"
        :class="{ rotateArrow: sub_menu2 }"
      />
    </a>

    <Logout />

    <ul class="sub-menu" id="sub_01" v-show="sub_menu">
      <li @click="sub_menu = false">
        <router-link to="/vendas">
          <img src="@/assets/img/icons/my-sales.svg" />
          Minhas Vendas
        </router-link>

        <router-link to="/contratos" v-if="!$store.getters.setPanel">
          <img src="@/assets/img/icons/my-contracts.svg" />
          Meus Contratos
        </router-link>
      </li>
    </ul>

    <ul class="sub-menu sub-menu2" id="sub_02" v-show="sub_menu2">
      <li @click="sub_menu2 = false">
        <b-tooltip
          target="club-menu"
          title="Para acessar, primeiro é necessario associar um produto ao Club."
          placement="left"
        />

        <a
          @click="eventLoginClub"
          id="club-menu"
          v-if="!$store.getters.setPanel"
        >
          <img src="@/assets/img/icons/arrow-club.svg" />
          Voomp Play
          <!-- <img class="logo-club" src="@/assets/img/logo-club.png" /> -->
        </a>

        <router-link to="/extrato" v-if="!$store.getters.setPanel">
          <img src="@/assets/img/icons/extrato.svg" />
          Extrato
        </router-link>
        <router-link
          to="/cupom"
          v-if="!$store.getters.setPanel || ($store.getters.setPanel && cupom)"
        >
          <img src="@/assets/img/icons/cupom.svg" />
          Cupom de Desconto
        </router-link>
        <router-link to="/central-de-notas" v-if="!$store.getters.setPanel"
          ><img src="@/assets/img/icons/notas.svg" />Central de
          Notas</router-link
        >
        <router-link to="/checkouts" v-if="!$store.getters.setPanel">
          <img src="@/assets/img/icons/grid.svg" />
          Checkouts
        </router-link>
        <router-link to="/afiliados" v-if="!$store.getters.setPanel">
          <img src="@/assets/img/icons/affiliate.svg" />
          Afiliados
        </router-link>
        <router-link to="/gestao-leads" v-if="!$store.getters.setPanel">
          <img src="@/assets/img/icons/award.svg" />
          Leads
        </router-link>
        <router-link to="/reclamacoes" v-if="!$store.getters.setPanel">
          <img src="@/assets/img/icons/triangle.svg" />
          Reclamações
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Logout from "./Logout.vue";
import Vue from "vue";
import Cookies from "js-cookie";

export default {
  components: { Logout },
  data() {
    return {
      sub_menu: false,
      sub_menu2: false,
      item: null,
      top: 0,
      bottom: 0,
      fimMenu: 300,
      fimSubmenu: 0,
      dashboard: false,
      vendas: false,
      cupom: false,
    };
  },
  computed: {
    clubIntegration() {
      return this.$store.getters.userClub;
    },
  },
  methods: {
    getPermissions() {
      if (this.$store.getters.setPanel) {
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          if (dados.find((x) => x.name === "DashboardRead")) {
            this.dashboard = true;
          }
          if (dados.find((x) => x.name === "SaleRead")) {
            this.vendas = true;
          }
          if (dados.find((x) => x.name === "CouponRead")) {
            this.cupom = true;
          }
        }
      }
    },
    eventLoginClub() {
      Vue.swal({
        title: "Acessar a VoompPlay",
        text: `Para acessar, primeiro é necessario associar um produto ao VoompPlay.`,
        type: "info",
        showCancelButton: true,
        confirmButtonText: "Entrar na VoompPLay",
        cancelButtonText: "Associar produto",
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        // console.log(result);
        if (result.isConfirmed) {
          this.$store.dispatch("clubLogin").catch(function() {});
        }
        if (result.dismiss == "cancel") {
          this.$router.push("/produtos");
        }
      });
    },
    subMenu01(e) {
      this.item = e.currentTarget;
      this.top = this.item.offsetTop;
      const submenu = document.querySelector("#sub_01");
      submenu.style = `top: ${this.top}px`;
      this.bottom = this.top + submenu.offsetHeight;
      this.fimSubmenu = this.fimMenu + submenu.offsetLeft - 71;

      this.sub_menu = !this.sub_menu;
      this.sub_menu2 = false;
    },
    subMenu02(e) {
      this.item = e.currentTarget;
      this.top = this.$store.getters.setPanel
        ? this.item.offsetTop
        : this.item.offsetTop - 400;
      const submenu = document.querySelector("#sub_02");
      submenu.style = `top: ${this.top}px`;

      console.log(this.top);
      this.bottom = this.top + submenu.offsetHeight;
      this.fimSubmenu = this.fimMenu + submenu.offsetLeft - 20;

      this.sub_menu2 = !this.sub_menu2;
      this.sub_menu = false;
    },

    eventMouse() {
      window.addEventListener("mousemove", (e) => {
        if (
          e.clientX > this.fimSubmenu ||
          (e.clientX > this.fimMenu && e.clientY < this.top) ||
          (e.clientX > this.fimMenu && e.clientY > this.bottom)
        ) {
          this.sub_menu = false;
          this.sub_menu2 = false;
        }
      });
    },
  },
  mounted() {
    this.eventMouse();
    this.getPermissions();
    this.$root.$on("reset-all", (data) => {
      this.getPermissions();
    });
    this.$root.$on("reset-permissions", (data) => {
      this.getPermissions();
    });
  },
};
</script>

<style scoped>
.ajust-itens {
  height: 15vh !important;
}
.menu-links {
  display: flex;
  flex-direction: column;
  height: 60vh;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0 !important;
}
.menu-links a {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 230px;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
  /* padding-left: 40px; */
  color: #333;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 15px;
  color: transparent;
  margin: 0 auto;
}
.menu-links a:hover {
  text-decoration: none;
  color: #002363;
}
.menu-links a img {
  filter: invert(50%);
  /* deixar o icone preto */
  /* filter: brightness(0) invert(0);; */
  transition: all 0.3s ease;
  margin: 0;
  margin-right: 40px;
  max-width: 22px;
}
.menu-links a.router-link-exact-active img,
.menu-links a:hover img {
  filter: invert(0);
}
.menu-links a.router-link-exact-active,
.menu-links a:hover {
  color: #002363 !important;
}

.menu-links a.router-link-exact-active::before {
  content: "";
  width: 7px;
  height: 7px;
  background: #002363;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  transition: all 0.3s ease;
}
img.arrow {
  /* margin: 0 !important; */
  margin-left: 10px !important;
}
/* Sub menu */
.sub-menu {
  width: 230px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
  padding: 25px;
  position: fixed;
  left: 200px;
  /* top: 200px; */
  z-index: 55555 !important;
  animation: showLeft 0.3s forwards;
}
#sub_02 {
  width: 280px;
}
#sub_03 {
  width: 280px;
}

.sub-menu li a {
  font-weight: 600;
  color: #333;
  font-size: 14px;
  padding: 10px;

  /* background: silver; */
}
.sub-menu li a.router-link-exact-active::before {
  /* display: block;
  position: absolute; */
  left: -15px;
}
.sub-menu li a:hover {
  text-decoration: none;
  color: #000;
}
.sub-menu li a + a {
  margin-top: 20px;
}
.sub-menu li a img {
  margin-right: 25px;
}
.Menu:hover .menu-links a {
  color: #333;
}

/* --- */
.sub-menu2 {
  top: 300px;
}
.sub-menu3 {
  top: 300px;
}
.rotateArrow {
  transform: rotate(-90deg);
}

.logo-club {
  max-width: 115px !important;
  width: 115px;
  margin-left: -3px !important;
}
</style>
