<template>
  <div>
    <slot name="button" :openModal="openModal"> </slot>
    <BaseModal :name="`confirm-dialog-${id}`" size="md" title="Confirmar Ação">
      <b-row>
        <b-col cols="12">
          Tem certeza que deseja continuar?
        </b-col>
      </b-row>

      <template v-slot:footer="{ cancel }">
        <BaseButton class="mr-4" variant="secondary_outline" @click="cancel">
          Cancelar
        </BaseButton>
        <BaseButton variant="secondary" @click="onSubmit">
          Continuar
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: parseInt(Math.random() * 1000),
    };
  },
  methods: {
    openModal() {
      this.$bvModal.show(`confirm-dialog-${this.id}`);
    },
    onSubmit() {
      this.$emit("confirmed");
      this.$bvModal.hide(`confirm-dialog-${this.id}`);
    },
  },
};
</script>
